import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Navbar from "./components/Navbar";
import BanPage from "./pages/BanPage";
import UbisoftUserPage from "./pages/UbisoftUserPage";

function App() {
    return (
        <>
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route path="/"/>
                    <Route path="/persona"/>
                    <Route path="/bans" element={<BanPage/>}/>
                    <Route path="/profile/:username/:userId" element={<UbisoftUserPage/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
