import RecentBans from "../components/RecentBans";
import { Component } from "react";
import { Card, CardHeader, CircularProgress, Container, Grid, IconButton, Paper, ThemeProvider } from "@mui/material";
import { DarkTheme } from "../Theme";
import axios from "axios";
import { ApiUrl } from "../Constants";
import { Block, Refresh } from "@mui/icons-material";

export default class BanPage extends Component {

    state = {
        bans: [],
        loading: false,
        error: false,
    }

    async componentDidMount() {
        await this.loadBans(50);
    }

    loadBans = async (amount: number) => {
        this.setState({ loading: true, bans: [], error: false })
        await axios({
            method: 'get',
            baseURL: ApiUrl,
            url: `v1/kvasir/users/bans?amount=${amount}`
        }).then((response) => this.setState({ bans: response.data, loading: false, error: false }))
            .catch(() => this.setState({ bans: [], loading: false, error: true }));
    }

    render() {
        return (
            <>
                {
                    <Grid container spacing={2}>
                        <Grid item xl>
                            <Container sx={{ justifyContent: 'center' }}>
                                <ThemeProvider theme={DarkTheme}>
                                    <Paper elevation={2} sx={{ paddingBottom: 1, mb: 2, mt: 2, borderRadius: '8px' }}>
                                        <Card sx={{ borderRadius: '8px' }}>
                                            <CardHeader title={"Recently banned players:"}
                                                        avatar={<Block sx={{ verticalAlign: 'middle' }}/>}
                                                        action={<IconButton
                                                            onClick={() => this.loadBans(50)}><Refresh/></IconButton>}/>
                                        </Card>
                                        {
                                            this.state.loading ?
                                                <>
                                                    <Container sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                        <CircularProgress color="primary"/>
                                                    </Container>
                                                </>
                                                :
                                                <RecentBans bans={this.state.bans}/>
                                        }
                                    </Paper>
                                </ThemeProvider>
                            </Container>
                        </Grid>
                    </Grid>
                }
            </>)
    }
}