import { Avatar, Button, Card, CardContent, CardHeader, Container, Divider, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import { DarkTheme } from "../Theme";
import { FaPlaystation } from "react-icons/fa";
import React, { useState } from "react";
import Ranks from "./Ranks";
import { SiUbisoft } from "react-icons/si";
import TabPanel from './TabPanel';
import moment from "moment";
import { NotInterested } from "@mui/icons-material";

const UbisoftView = (props: { user: null, ranks: any[], aliases: any[], recentPlayers: any[], bans: any[], platforms: any[] }) => {

    const [hidden, setHidden] = useState(true);

    document.title = `${props.user?.["username"]} - Kvasir`

    return (
        <>
            {
                props.user ?
                    <>
                        <Container sx={{ justifyContent: 'center' }}>
                            <ThemeProvider theme={DarkTheme}>
                                <Card sx={{ mt: 2, mb: 2, padding: 1, borderRadius: '8px' }}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs>
                                                {
                                                    props?.bans?.length > 0 ?
                                                        <>
                                                            <Paper elevation={2} sx={{padding: 1, mb: 2, borderRadius: '8px'}}>
                                                                <CardHeader title={"Bans:"} avatar={<NotInterested/>}/>
                                                                <Divider sx={{mb: 2}} />
                                                                {
                                                                    props.bans.map((key, index) =>
                                                                        <>
                                                                            <Card sx={{
                                                                                borderLeft: '4px solid darkred',
                                                                                borderRadius: '4px',
                                                                            }}>
                                                                                <CardHeader
                                                                                    title={
                                                                                        <Typography>{props.bans[index]["banReason"]}</Typography>}
                                                                                    subheader={moment.utc(props.bans[index]["bannedAt"]).local().format("DD.MM.YYYY")}/>
                                                                            </Card>
                                                                        </>
                                                                    )
                                                                }
                                                            </Paper>
                                                        </>
                                                        :
                                                        null
                                                }
                                                <Paper elevation={2}
                                                       sx={{
                                                           padding: 1,
                                                           borderRadius: '8px'
                                                       }}>
                                                    {
                                                        hidden ?
                                                            props.ranks ?
                                                                <>
                                                                    <Ranks ranks={props.ranks} size={8}/>
                                                                </>
                                                                :
                                                                null
                                                            :
                                                            <>
                                                                <Ranks ranks={props.ranks} size={props.ranks.length}/>
                                                            </>
                                                    }
                                                    <Container sx={{ justifyContent: 'center', display: 'flex' }}>
                                                        {
                                                            hidden ?
                                                                <Button variant={"contained"} sx={{ mb: 2 }}
                                                                        onClick={() => setHidden(false)}>Show
                                                                    more</Button>
                                                                :
                                                                <Button variant={"contained"} sx={{ mb: 2 }}
                                                                        onClick={() => setHidden(true)}>Show
                                                                    less</Button>
                                                        }
                                                    </Container>
                                                </Paper>
                                            </Grid>
                                            <Grid item xl={8} xs={12}>
                                                <Paper elevation={2} sx={{ borderRadius: '8px' }}>
                                                    <CardHeader title={<Typography
                                                        component={"span"}>{props.user["platform"] === "psn" ?
                                                        <FaPlaystation
                                                            style={{ verticalAlign: 'middle', marginRight: '8px' }}/> :
                                                        <SiUbisoft
                                                            style={{
                                                                verticalAlign: 'middle',
                                                                marginRight: '8px'
                                                            }}/>}<Typography
                                                        component={"span"}>{props.user["username"]}</Typography></Typography>}
                                                                avatar={<Avatar src={props.user["avatarUrl"]}
                                                                                sx={{
                                                                                    height: '5rem',
                                                                                    width: '5rem'
                                                                                }}/>}/>
                                                </Paper>
                                                <Paper elevation={2} sx={{ borderRadius: '8px', mt: 2 }}>
                                                    <TabPanel platforms={props.platforms} profile={props.user}
                                                              recents={props.recentPlayers}/>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </ThemeProvider>
                        </Container>
                    </>
                    : null
            }
        </>
    )
}

export default UbisoftView;