import { Avatar, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import React from "react";
import { MilitaryTech } from "@mui/icons-material";
import { GetSeasonColor } from "../Constants";

const Ranks = (props: { ranks: any[], size: number }) => {
    return (
        <>
            <CardHeader avatar={<MilitaryTech/>} subheader={"Top Ranks:"}/>
            <Divider />
            <br/>
            {
                props.ranks.slice(0, props.size).map((key, index) =>
                    <>
                        <Card sx={{
                            mb: 2,
                            borderLeft: `4px solid ${GetSeasonColor(index)}`,
                            borderRadius: '4px',
                        }}>
                            {/*                            <CardHeader avatar={<Avatar src={props.ranks[rIndex]["imageUrl"]} />} 
                                        title={<Typography component={"span"} color={props.ranks[rIndex]["seasonColor"]}><b>
                                            {props.ranks[rIndex]["season"]}</b>
                                    <Typography component={"span"} sx={{float: 'right'}} color={"floralwhite"}><b>{props.ranks[rIndex]["kd"]}</b> K/D</Typography>
                            </Typography>}
                                        subheader={<Typography component={"span"}><br/>{props.ranks[rIndex]["maxRank"]}<Typography component={"span"} sx={{float: 'right'}}>{props.ranks[rIndex]["matches"]} Matches</Typography></Typography>}/>*/}
                            <CardContent>
                                <Typography
                                    color={GetSeasonColor(index)} sx={{ mb: 1 }}>{props.ranks[index]["season"]}</Typography>
                                <Avatar sx={{ float: 'left', verticalAlign: 'middle' }}
                                        src={`https://kvasir-images.digneety.net/v2/r6siege/ranks/${props.ranks[index]["maxRank"]}.png`}/>
                                <Typography variant="body2">
                                    <b>{props.ranks[index]["maxRank"]}</b> • {props.ranks[index]["maxMmr"]} MMR
                                </Typography>
                                <Typography variant="body2">
                                    <b>{props.ranks[index]["kd"]}</b> K/D • <b>{props.ranks[index]["matches"]}</b> Matches
                                </Typography>
                            </CardContent>
                        </Card>
                    </>
                )
            }
        </>
    )
}

export default Ranks;