import { Alert, AppBar, Avatar, Box, Button, Card, CardHeader, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Snackbar, TextField, ThemeProvider, Toolbar, Typography } from "@mui/material";
import { DarkTheme } from "../Theme";
import { useState } from "react";
import { getUser } from "../API";
import { URL } from "../Constants";

function Navbar() {
    
    const [open, setOpen] = useState(false);

    const [username, setUsername] = useState("");

    const [profiles, setProfiles] = useState([]);

    const [loading, setLoading] = useState(false);

    const [platform, setPlatform] = useState("");

    const [error, setError] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setPlatform(event.target.value);
        sessionStorage.setItem("platform", event.target.value);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const search = async (username: string, platform: string) => {
        setProfiles([]);
        setLoading(true);
        setError(false);
        await getUser(username, platform).then(data => setProfiles(data["profiles"])).then(() => setLoading(false)).catch(() => setError(true))
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <ThemeProvider theme={DarkTheme}>
                    <AppBar position={"static"}>
                        <Toolbar>
                            <Typography onClick={() => handleOpen()} variant="h6" color="secondary" component="div">
                                <img id="logo" alt="logo" src={`https://kvasir-images.digneety.net/v1/kvasir-logo.png`} style={{ maxWidth: '95px', marginTop: '10px' }} />
                            </Typography>
                            <Button key="search" sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleOpen()}>Search</Button>
                            <Button
                                key="persona"
                                href={`${URL}/persona`}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                                Nickname
                            </Button>
                            <Button
                                key="bans"
                                href={`${URL}/bans`}
                                sx={{ my: 2, color: 'white', display: 'block' }}>
                                Recent Bans
                            </Button>
                        </Toolbar>
                    </AppBar>
                </ThemeProvider>
            </Box>
            <ThemeProvider theme={DarkTheme}>
                <Dialog open={open} onClose={() => handleClose()}>
                    <>
                        <DialogTitle>
                            {"Search for a User"}
                        </DialogTitle>
                        <DialogContent>
                            <Container sx={{ justifyContent: 'center', display: 'flex', mt: 4 }}>
                                <TextField id="standard-basic" label="Username" variant="standard"
                                           onChange={(event) => setUsername(event.target.value)}
                                           onKeyDown={(event => event.key === 'Enter' ? search(username, platform) : null)}/>
                                <FormControl variant="standard" sx={{ ml: 2, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-standard-label">Platform</InputLabel>
                                    <Select
                                        labelId="search-user"
                                        id="search-user-input"
                                        value={platform}
                                        onChange={handleChange}
                                        label="Platform"
                                    >
                                        <MenuItem value={"pc"}>PC</MenuItem>
                                        <MenuItem value={"psn"}>PSN</MenuItem>
                                    </Select>
                                </FormControl>
                            </Container>
                            {
                                loading ?
                                    <>
                                        <Container sx={{ justifyContent: 'center', display: 'flex', mt: 2 }}>
                                            <CircularProgress color={"primary"}/>
                                        </Container>
                                    </>
                                    : null
                            }
                            <br/>
                            {
                                profiles.length > 0 ?
                                    profiles.map((key, index) =>
                                        <>
                                            <Container sx={{ justifyContent: 'center', mt: 2 }}>
                                                <Paper elevation={2}>
                                                    <Card sx={{ mb: 2 }}>
                                                        <CardHeader
                                                            avatar={<Avatar src={profiles[index]["imageUrl"]}/>}
                                                            title={<Typography component={"a"} color={"primary"}
                                                                               sx={{ textDecoration: 'none' }} href={`${URL}/profile/${profiles[index]["name"]}/${profiles[index]["profileId"]}`}>{profiles[index]["name"]}</Typography>}
                                                            subheader={profiles[index]["platform"]}
                                                        onClick={() => window.location.href = `${URL}/profile/${profiles[index]["name"]}/${profiles[index]["profileId"]}`} className={"clickable-card"}/>
                                                    </Card>
                                                </Paper>
                                            </Container>
                                        </>
                                    ) : null
                            }
                            {
                                error ?
                                    <Snackbar>
                                        <Alert severity={"error"}>Could not load profiles.</Alert>
                                    </Snackbar> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} sx={{ ml: 2 }} onClick={async () => handleClose()}>Close</Button>
                            <Button variant={"contained"} sx={{ ml: 2 }} onClick={async () => await search(username, platform)}>Search</Button>
                        </DialogActions>
                    </>
                </Dialog>
            </ThemeProvider>
        </>
    )
}

export default Navbar;