import axios from "axios";
import { ApiUrl } from "./Constants";
import { createContext } from "react";

export const getUser = async (username: string, platform: string) => {
    let data = await axios({
        method: 'get',
        baseURL: ApiUrl,
        url: `/v1/kvasir/users/profiles/${username}`,
        headers: {
            "Platform": platform
        }
    });
    return data.data;
}