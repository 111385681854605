import React, { useEffect, useState } from 'react'
import { Await, useParams } from "react-router-dom";
import { Alert, Card, CardContent, CircularProgress, Container, Snackbar, ThemeProvider } from "@mui/material";
import axios from "axios";
import UbisoftView from "../components/UbisoftView";
import { ApiUrl } from "../Constants";

export default function UbisoftUserPage() {

    const [profiles, setProfiles] = useState(null);
    const [aliases, setAliases] = useState<any[]>([]);
    const [platforms, setPlatforms] = useState<any[]>([]);
    const [ranks, setRanks] = useState<any[]>([]);
    const [recentPlayers, setRecentPlayers] = useState<any[]>([]);
    const [bans, setBans] = useState<any[]>([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    let { username, userId }: any = useParams();

    const search = async () => {
        await axios({
            method: 'get',
            baseURL: ApiUrl,
            url: `/v1/kvasir/users/${userId}`,
            headers: {
                "platform": sessionStorage.getItem("platform") === null ? "pc" : sessionStorage.getItem("platform")
            }
        }).then((response => {
            setProfiles(response.data["profile"])
            setRecentPlayers(response.data["recents"])
            setPlatforms(response.data["platforms"])
            setRanks(response.data["ranks"])
            setAliases(response.data["aliases"])
            setBans(response.data["bans"])
            setLoading(false)
        })).catch(_ => {
            setError(true)
            setLoading(false)
        });
    }

    useEffect(() => {
        search()
    }, []);

    return (
        <>
            {
                <>
                    {
                        error ?
                            <>
                                <Snackbar open={true} autoHideDuration={5000}
                                          onClose={() => setError(false)}>
                                    <Alert severity="error"><b>{username}</b> not found.</Alert>
                                </Snackbar>
                            </>
                            :
                            null
                    }
                    {
                        loading ?
                            <>
                                <Container sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <CircularProgress color="secondary"/>
                                </Container>
                            </>
                            : null
                    }
                    <UbisoftView bans={bans}
                                 platforms={platforms}
                                 aliases={aliases}
                                 recentPlayers={recentPlayers}
                                 ranks={ranks}
                                 user={profiles}
                    />
                </>
            }
        </>
    );
}
