import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Avatar, Card, CardContent, CardHeader, Grid, Paper } from "@mui/material";
import { URL } from "../Constants";
import { FaPlaystation } from "react-icons/fa";
import { SiUbisoft } from "react-icons/si";
import moment from "moment/moment";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `panel-${index}`,
    };
}

export default function BasicTabs(props: { platforms: any[], recents: any[], profile: null }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{  borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Platforms" {...a11yProps(0)} />
                    <Tab label="Recents" {...a11yProps(1)} />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                {
                    props?.platforms?.map((key, index) =>
                        <>
                            <Card sx={{ mt: 1, mb: 2 }}>
                                <CardHeader title={
                                    <Typography component={"a"} sx={{ textDecoration: 'none' }}
                                                href={props?.platforms?.[index]?.["profileUrl"]}
                                                color={"primary"}>{props?.platforms?.[index]?.["name"]}
                                    </Typography>}
                                            subheader={props?.platforms?.[index]?.["platform"]}
                                />
                            </Card>
                        </>
                    )
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    props?.recents?.map((key, index) =>
                        <>
                            <Card sx={{ mt: 1, mb: 2 }}>
                                <CardHeader title={
                                    <Typography component={"span"}>
                                        {
                                            props?.recents[index]?.["platform"] === "psn" ?
                                                <FaPlaystation
                                                    style={{ verticalAlign: 'middle', marginRight: '8px' }}/> :
                                                <SiUbisoft
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        marginRight: '8px'
                                                    }}/>
                                        }
                                        <Typography component={"a"}
                                                    sx={{ textDecoration: 'none', verticalAlign: 'middle' }}
                                                    href={`${URL}/profile/${props?.recents[index]?.["userId"]}`}
                                                    color={"primary"}>{props?.recents[index]?.["username"]}
                                        </Typography>
                                    </Typography>}
                                            avatar={<Avatar
                                                src={props?.recents[index]?.["imageUrl"]}
                                                sx={{ verticalAlign: 'middle' }}/>}/>
                            </Card>
                        </>
                    )
                }
            </TabPanel>
        </Box>
    );
}