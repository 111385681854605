//export const URL = "http://localhost:3000";
//export const ApiUrl = "https://kvasir.digneety.net"
//export const ApiUrl = "https://localhost:7002"
export const URL = "https://gamma.digneety.net"
export const ApiUrl = "https://quasar.digneety.net";

export const GetSeasonColor = (seasonId: number) => {
    switch (seasonId) {
        case 1:
            return '#2e93b3'
        case 2:
            return '#d0a344'
        case 3:
            return '#47893b'
        case 4:
            return '#bd1e2c'
        case 5:
            return '#723093'
        case 6:
            return '#0050b3'
        case 7:
            return '#ca361c'
        case 8:
            return '#006543'
        case 9:
            return '#ffc113'
        case 10:
            return '#949f39'
        case 11:
            return '#81a0c1'
        case 12:
            return '#aa854f'
        case 13:
            return '#d2005a'
        case 14:
            return '#304395'
        case 15:
            return '#156309'
        case 16:
            return '#156309'
        case 17:
            return '#946a97'
        case 18:
            return '#946a97'
        case 19:
            return '#6ca511'
        case 20:
            return '#d14007'
        case 21:
            return '#ac0000'
        case 22:
            return '#009cbe'
        case 23:
            return '#ffa200'
        case 24:
            return '#587624'
        case 25:
            return '#ffb52c'
        case 26:
            return '#60cdb0'
        case 27:
            return '#dac925'
        case 28:
            return '#d03314'
        case 29:
            return '#45abf3'
        default:
            return '#45abf3'
    }
}