import { Avatar, Card, CardHeader, Typography } from "@mui/material";
import { URL } from "../Constants";
import moment from "moment";
import { DarkTheme } from "../Theme";

const RecentBans = (props: { bans: any[] }) => {
    return (
        <>
            {
                props.bans ?
                    <>
                        {props.bans.map((key, index) =>
                            <>
                                <Card sx={{ mt: 2, ml: 5, mr: 5, mb: 2, borderRadius: '8px' }}>
                                    <CardHeader key={key} avatar={<Avatar
                                        src={props.bans[index]["imageUrl"]} alt={"pfp"}/>}
                                                title={<Typography component={"a"}
                                                                   href={`${URL}/profile/${props.bans[index]["name"]}/${props.bans[index]["userId"]}`}
                                                                   sx={{ textDecoration: 'none' }}
                                                                   color={"primary"}>{props.bans[index]["name"]}</Typography>}
                                                subheader={
                                                    <Typography
                                                        color={DarkTheme.custom.banned}>{props.bans[index]["reason"]}
                                                        <Typography sx={{ float: 'right' }}
                                                                    color={"floralwhite"}>{moment.utc(props.bans[index]["bannedAt"]).local(true).format("DD.MM.YYYY HH:mm")}</Typography></Typography>}/>
                                </Card>
                            </>
                        )}
                    </>
                    : null
            }
        </>
    );
}
export default RecentBans;