import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Theme {
        custom: {
            banned: string;
        };
    }
    interface ThemeOptions {
        custom?: {
            banned?: string;
        };
    }
}

export const DarkTheme = createTheme({
    custom: {
        banned: '#FF0038'
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#c51162',
        },
        secondary: {
            main: '#c218b1',
        },
        background: {
            paper: '#0d1220',
            default: '#141b2d',
        },
    },
});